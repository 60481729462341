import Header from "./Header";
// import "../stylesheets/Gallery.css";
import Footer from "./Footer";
const Gallery = () => {
  return (
    <div>
      <Header />
      <div className="gallery-box1">
        <div className="gallery-rec1"></div>
        <div className="h4">School Uniform</div>
      </div>
      <div className="gallery-box1">
        <div className="gallery-rec1"></div>
        <div className="h4">School Campus</div>
      </div>
      <div className="gallery-box1">
        <div className="gallery-rec1"></div>
        <div className="h4">School Ground</div>
      </div>
      <div className="gallery-box1">
        <div className="gallery-rec1"></div>
        <div className="h4">School Bus</div>
      </div>
      <div className="gallery-box1">
        <div className="gallery-rec1"></div>
        <div className="h4">Kinder Garden</div>
      </div>
      <div className="gallery-box1">
        <div className="gallery-rec1"></div>
        <div className="h4">Annual Day</div>
      </div>
      <div className="gallery-box1">
        <div className="gallery-rec1"></div>
        <div className="h4">Rituals celebrated</div>
      </div>
      <div className="gallery-box1">
        <div className="gallery-rec1"></div>
        <div className="h4">Journeys</div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
