import { useNavigate } from "react-router-dom";
// import "../stylesheets/Header.css";
import Logo from "../../../resources/header/logo.png";

const Header = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path); 
  };

  return (
    <div className="b1-header">
      <div className="b1-logo-title">
        <div className="logo">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "114px", height: "114px" }}
          />
        </div>
        <div className="b1-school-name">
          Sandeepani Hi-Tech School, Bangalore
        </div>
      </div>
      <div className="b1-nav-buttons">
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool")}
        >
          Home
        </button>
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool/about")}
        >
          About Us
        </button>
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool/faculties")}
        >
          Faculties
        </button>
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool/admissions")}
        >
          Admissions
        </button>
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool/facilities")}
        >
          Facilities
        </button>
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool/gallery")}
        >
          Gallery
        </button>
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool/news")}
        >
          News
        </button>
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool/alumina")}
        >
          Alumina
        </button>
        <button
          className="b1-button"
          onClick={() => navigateTo("/sandeepanihitechschool/contact")}
        >
          Contact Us
        </button>
        <button className="b1-button" onClick={() => navigateTo("/")}>
          Main Site
        </button>
        <button className="b1-button" onClick={() => navigateTo("/sandeepanihitechschool/login")}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Header;
