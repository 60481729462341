import Header from "./Header";
import Footer from "./Footer";
// import "../stylesheets/Home.css";
import branch1_home from "../../../resources/images/branch1-home.png";
import br1_img from "../../../resources/images/br1-img.png";
import admission from "../../../resources/images/admission.svg";
import campus from "../../../resources/images/campus.svg";
import location from "../../../resources/images/location.svg";
import facility from "../../../resources/images/facility.svg";
import gallery from "../../../resources/images/gallery.svg";
import transport from "../../../resources/images/transport.svg";
import principal from "../../../resources/images/principal.svg";
import top1 from "../../../resources/images/top1.png";
import top2 from "../../../resources/images/top2.png";
import top3 from "../../../resources/images/top3.png";
import top4 from "../../../resources/images/top4.png";

const Home = () => {
  window.scroll(0,0);
  return (
    <div>
      <Header />
      <div className="home1-container">
        {/* <div className="br1_home_img"> */}
        {/* <img src={branch1_home} alt="Branch1_Home " /> */}
        <div className="home-school-img">
          <img
            src={branch1_home}
            alt="img"
            style={{ width: "1200px", height: "500px" }}
          />
          <div className="textHeading">
            <h1 className="center_text">
              "Welcome to Saandipini Hi-Tech School, where learning begins with
              endless possibilities."
            </h1>
            <h2 className="text2">Get started with us today.</h2>
            <button className="btn_contact">Contact Us</button>
          </div>
        </div>

        <div className="welcome_div">
          <p>Welcome to Sandeepani Group of Institutions</p>
          <p>Where Education meets Innovation!</p>
        </div>
        {/* About Sandipanni */}
        <div className="box1">
          <div className="rec1" />
          <div className="abt-school">
            <h4>About Saandipini Hi-Tech school</h4>
            <p>
              "Welcome to Sandipeni Hi-Tech School, Bangalore. A pioneering
              institution where cutting-edge technology meets holistic
              education. Our school is dedicated to fostering academic
              excellence, innovative thinking, and personal growth in a dynamic
              and supportive environment. With state-of-the-art facilities and a
              dedicated team of educators, we strive to prepare our students for
              a future where they can excel both academically and personally."
            </p>
          </div>
        </div>
        {/* best school in banglore */}
        <div className="best-school">
          <div className="br1-img">
            <img src={br1_img} alt="school_images" />
            <div className="header-container">
              <h2>Best schools in Bangalore</h2>
            </div>

            <div className="flex-container">
              <div className="iframe-container">
                <iframe
                  width="600"
                  height="315"
                  src="https://www.youtube.com/embed/jBX6Xk0E508?si=xD-_GYBe1kjnwX1t"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="description-container">
                <h3>Virtual School Tour</h3>
                <p>
                  Our institution is designed to offer a modern learning
                  experience, integrating the latest technology with a
                  comprehensive curriculum that emphasizes both academic rigor
                  and personal development.
                </p>
              </div>
            </div>
            {/* Quick Links */}
          </div>
        </div>

        <div className="quickLinkContainer">
          <h1>Quick Links</h1>

          <div className="gridContainer">
            <div className="gridItem">
              <img src={admission} alt="Admission Icon" />
              <h2>ADMISSION</h2>
            </div>

            <div className="gridItem">
              <img src={campus} alt="campus" />
              <h2>OUR CAMPUS</h2>
            </div>

            <div className="gridItem">
              <img src={gallery} alt="Gallery Icon" />
              <h2>GALLERY</h2>
            </div>

            <div className="gridItem">
              <img src={facility} alt="Facilities Icon" />
              <h2>FACILITIES</h2>
            </div>

            <div className="gridItem">
              <img src={location} alt="Location Icon" />
              <h2>LOCATION</h2>
            </div>

            <div className="gridItem">
              <img src={transport} alt="Transportation Icon" />
              <h2>TRANSPORTATION</h2>
            </div>
          </div>
        </div>

        <div className="cards-container">
          <div className="card">
            <div className="card-header">Indoor Activities</div>
            <ul>
              <li>Table Tennis</li>
              <li>Play Area</li>
              <li>Board Game</li>
              <li>Chess</li>
              <li>Carrom</li>
            </ul>
          </div>
          <div className="card">
            <div className="card-header">Outdoor Activities</div>
            <ul>
              <li>Basket Ball</li>
              <li>Shuttle Badminton</li>
              <li>Football</li>
              <li>Volley Ball</li>
              <li>Kabaddi</li>
              <li>Trow Ball</li>
            </ul>
          </div>
          <div className="card">
            <div className="card-header">Co curricular Activities</div>
            <ul>
              <li>Yoga & Meditation</li>
              <li>Music</li>
              <li>Dance</li>
              <li>Art & Craft</li>
              <li>Karate</li>
            </ul>
          </div>
        </div>

        {/* Princnipal Desk */}
        <div className="principalDesk">
          <div className="box1">
            <div className="rec1" />
            <div className="abt-school">
              <h4>Principal desk</h4>
              <div className="gridDesk">
                <div className="gridItems item1">
                  <img
                    src={principal}
                    alt="Principal_Image"
                    style={{ width: "600px", height: "400px" }}
                  />
                </div>
                <div className="gridItems item2">
                  <p>
                    "As the principal of our school, I firmly believe that
                    fostering a positive and inclusive school culture is
                    fundamental to our success. We prioritize creating a safe
                    and welcoming environment where every student feels valued
                    and heard. This commitment is reflected in our diverse
                    programs, such as our peer mentorship initiative, which
                    pairs older students with younger ones to build
                    relationships and support.
                  </p>
                </div>
                <div className="gridItems item3">
                  <p>
                    Moreover, we regularly hold community forums to involve
                    parents and local organizations in our school’s journey.
                    This not only strengthens our ties with the community but
                    also enriches our students' learning experiences. By
                    encouraging collaboration and open communication, we create
                    a vibrant school community where everyone can thrive."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Top performers */}
        <div className="box1">
          <div className="rec1" />
          <div className="abt-school">
            <h4>Top performers</h4>
            <div className="flexTop">
              <div className="top1">
                <img
                  src={top1}
                  alt="Top-performer1"
                  style={{ width: "350px", height: "300px" }}
                />
                <h3
                  className="h3Top"
                  style={{
                    color: "#00008B",
                    fontWeight: "bold",
                    width: "350px",
                  }}
                >
                  Sudharshan Adiga <br /> Percentage: 90%
                </h3>
              </div>
              <div className="top1">
                <img
                  src={top2}
                  alt="Top-performer2"
                  style={{ width: "350px", height: "300px" }}
                />
                <h3
                  className="h3Top"
                  style={{
                    color: "#00008B",
                    fontWeight: "bold",
                    width: "350px",
                  }}
                >
                  Charmi <br /> Percentage:89%
                </h3>
              </div>
              <div className="top1">
                <img
                  src={top3}
                  alt="Top-performer3"
                  style={{ width: "350px", height: "300px" }}
                />
                <h3
                  className="h3Top"
                  style={{
                    color: "#00008B",
                    fontWeight: "bold",
                    width: "350px",
                  }}
                >
                  Ajay G <br /> Percentage: 80%
                </h3>
              </div>
              <div className="top1">
                <img
                  src={top4}
                  alt="Top-performer4"
                  style={{ width: "350px", height: "300px" }}
                />
                <h3
                  className="h3Top"
                  style={{
                    color: "#00008B",
                    fontWeight: "bold",
                    width: "350px",
                  }}
                >
                  Krupa <br />
                  Percentage:80%
                </h3>
              </div>
            </div>
          </div>
        </div>

        {/* COnclusion */}
        <div className="containerConclu">
          <div className="contentConclu">
            <h1 className="title">
              Considering{" "}
              <span style={{ color: "#FF0000" }}>
                Saandepani Hi-Tech School?
              </span>
            </h1>
            <div className="flex-containerConclu">
              <div className="left-section">
                <p>
                  Saandeepini Hi-Tech School in Bangalore focuses on holistic
                  education, blending technology with traditional learning. The
                  school emphasizes innovative teaching methods, fostering
                  creativity and critical thinking among students. With modern
                  facilities and a commitment to academic excellence, it aims to
                  prepare students for future challenges.
                </p>
              </div>
              <div className="right-section">
                <div className="gridConclu">
                  <div className="grid-itemConclu">
                    <span className="info">230+</span>
                    <span>Students</span>
                  </div>
                  <div className="grid-itemConclu">
                    <span className="info">18</span>
                    <span>Dedicated Staff</span>
                  </div>
                  <div className="grid-itemConclu">
                    <span className="info">4</span>
                    <span>School Bus</span>
                  </div>
                  <div className="grid-itemConclu">
                    <span className="info">7</span>
                    <span>Non teaching Staff</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="redBox">
          
          
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
