import Header from "./Header";
import Footer from "./Footer";
// import "../stylesheets/Alumina.css";
const Alumina = () => {
  return (
    <div>
      <Header />
      Here goes the Alumina page
      <Footer />
    </div>
  );
};

export default Alumina;
