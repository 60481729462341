import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
// import "../stylesheets/Home.css";
import { useNavigate } from "react-router-dom";
import image1 from "../resources/home/branch1.jpeg";
import image2 from "../resources/home/branch2.jpeg";
import image3 from "../resources/home/branch3.jpeg";
import image4 from "../resources/home/branch4.png";
import image5 from "../resources/home/branch5.png";
import image6 from "../resources/home/branch6.png";
import image8 from "../resources/home/snap2.png";
import image9 from "../resources/home/snap3.png";
import image10 from "../resources/home/snap4.png";
import image11 from "../resources/home/chairman.jpg";
import image12 from "../resources/home/new img director.jpeg";
import UpArrow from "../utilities/UpArrow";

const Home = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Header />
      <div className="home-container">
        <div className="home-title1">
          Welcome to Sandeepani Group of Institutions
        </div>
        <div className="home-title2">Where Education meets Innovation!</div>
        <div className="home-school-img">
          <img
            src={image1}
            alt="img"
            // style={{ width: "1080px", height: "500px" }}
          />
        </div>
        <div className="home-title3">
          Explore Our Branches
          <br />
        </div>

        <div className="branches">
          <div className="branch branch1">
            <div className="box1">
              <div className="rec1" />
              <div className="h4">Sandipini Hi-Tech School, Bangalore</div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={image2} alt="branch1" />
              <div className="overlay-text">
                Sandeepani Hi-Tech School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>

          <div className="branch branch2">
            <div className="box1">
              <div className="rec1" />
              <div>
                <div className="h4">
                  Sandeepani National Public School, Ajjampura
                </div>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={image3} alt="branch2" />
              <div className="overlay-text">
                Sandeepani National Public School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>

          <div className="branch branch3">
            <div className="box1">
              <div className="rec1" />
              <div>
                <div className="h4">
                  Sandeepani Indian International Public School, Hosadurga
                </div>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={image4} alt="branch3" />
              <div className="overlay-text">
                Sandeepani Indian International Public School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>

          <div className="branch branch4">
            <div className="box1">
              <div className="rec1" />
              <div>
                <div className="h4">
                  Sandeepani International Residential School, Talikatte
                </div>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={image5} alt="branch4" />
              <div className="overlay-text">
                Sandeepani International Residential School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>

          <div className="branch branch5">
            <div className="box1">
              <div className="rec1" />
              <div>
                <div className="h4">
                  Sandeepani International School, Holalkere
                </div>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => navigateTo(`/sandeepanihitechschool`)}
            >
              <img src={image6} alt="branch5" />
              <div className="overlay-text">
                Sandeepani International School
                <div className="website-button">View Website</div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-info1">
          <div className="box1">
            <div className="rec1" />
            <div className="h4">Why Sandeepani</div>
          </div>

          <div className="text-container1">
            <img className="img" src={image8} alt="Image8" />
            <div className="title-para">
              <div className="h2">Comprehensive Curriculum</div>
              <p>
                Our curriculum is carefully designed to provide a well-rounded
                education and foster a love for learning. From core subjects to
                extracurricular activities, we offer a wide range of programs
                that cater to students' diverse interests and abilities.
              </p>
            </div>
          </div>
          <div className="text-container2">
            <div >
              <div className="h2">Innovative Teaching Methods</div>
              <p>
                We believe in leveraging technology to enhance the learning
                experience. With state-of-the-art facilities, smart classrooms,
                and interactive learning materials, we create an engaging
                environment that encourages active participation and critical
                thinking.
              </p>
            </div>
            <img className="img" src={image9} alt="img" />
          </div>

          <div className="text-container1">
            <img className="img" src={image1} alt="img" />
            <div>
              <div className="h3">Experienced and Caring Faculty</div>
              <p>
                Our team of dedicated educators is passionate about teaching and
                genuinely cares about the success of each student. They foster a
                nurturing atmosphere where students feel supported, motivated,
                and inspired to reach their full potential.
              </p>
            </div>
          </div>
          <div className="text-container2">
            <div className="par">
              <div className="h2">Language Development</div>
              <p>
                Our language development program focuses on honing communication
                skills, enhancing vocabulary, and fostering a love for
                languages, both local and international.
              </p>
            </div>
            <img className="img" src={image10} alt="img" />
          </div>
        </div>

        <div className="home-info1">
          <div className="box1">
            <div className="rec1"></div>
            <div className="mission-title">Mission & Vision</div>
          </div>

          <div className="mission-vision-div">
            <br />
            <div className="mission">
              <div className="h5">Mission</div>
              <p className="par1">
                Our goal is to build skills that set children up for success in
                the Sandeepani Group of Schools and beyond.
              <br></br>
                We believe in providing a holistic that prepares students for
                the challenges and opportunities of a rapidly changing world{" "}
              </p>
            </div>
            <div className="vision">
              <div className="vis">Vision</div>
              <p className="par3">
                To educate all students to the highest levels of academic
                achievement, to enable them to reach and expand their potential,
                and to prepare them to become productive, responsible, ethical,
                creative, and compassionate members of society.
              </p>
            </div>
          </div>
        </div>

        <div className="home-info1">
          <div className="box1">
            <div className="rec1"></div>
            <div className="h4">Chairman's Desk</div>
          </div>
          <div className="sec1-container">
            <div className="img2">
              <img src={image11} alt="img4" />
            </div>
            <div className="position">Chairman</div>
            <div className="chair-man">Lokesh Talikatte</div>
            <div className="description">
              <div className="quote"></div>

              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                Students today have an unquenchable thirst to seek knowledge
                that cannot be satiated by traditional methods of imparting
                education like rote learning and passive listening. With the
                paradigm shift in the field of education, children at Sandeepani
                group of institutions will be provided with opportunities and
                situations that facilitate them to exhibit their two-hidden
                potential to the fullest.
              </div>
              <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                We believe that a good education is one that shapes the growth
                of an individual’s creativity, knowledge, and character. Our
                accredited curriculum is designed to recognize individual
                differences and foster the development of each student’s
                intellect and personality. We strive to meet the needs of the
                whole child and encourage our students to explore, inquire, and
                to think critically.
                <br />
              </div>
              <button class="read-more-button" onClick={toggleReadMore}>
                {" "}
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>

          <div className="box1">
            <div className="rec1"></div>
            <div className="h4">Director’s Desk</div>
          </div>

          <div className="sec1-container">
            <div className="img2">
              <img src={image12} alt="img4" />
            </div>
            <div className="description">
              <div className="section">
                Dear Students, Parents, and Staff of Sandeepani Group of
                Schools,
              </div>
              <div className="director-content">
                <p className="visible-content">
                  As the Director of Sandeepani Group of Schools, it is with
                  immense pleasure and pride that I welcome you to our vibrant
                  educational community. Our institution stands as a beacon of
                  excellence, committed to nurturing young minds and fostering a
                  lifelong love for learning.
                </p>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  At Sandeepani, we believe in holistic education that goes
                  beyond textbooks and classrooms. Our mission is to cultivate
                  an environment where students can explore their passions,
                  develop critical thinking skills, and grow into responsible
                  global citizens. We are dedicated to providing a balanced
                  curriculum that integrates academic rigor with co-curricular
                  activities, ensuring our students emerge well-rounded and
                  prepared for the challenges of the future.
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  Our team of dedicated educators is at the heart of this
                  mission. Their unwavering commitment to student success,
                  combined with innovative teaching methodologies, creates a
                  dynamic and engaging learning experience. We continuously
                  strive to stay at the forefront of educational advancements,
                  incorporating the latest technologies and pedagogical
                  practices to enhance our students' learning journey. Parents
                  play a crucial role in the educational process, and we value
                  your partnership and support. Together, we can create a
                  nurturing and motivating atmosphere that encourages our
                  children to aim high and achieve their dreams. We encourage
                  open communication and collaboration, as your insights and
                  feedback are invaluable to us.
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  To our students, I say this: Dream big, work hard, and stay
                  curious. Embrace every opportunity to learn and grow, both
                  inside and outside the classroom. Remember that education is
                  not just about acquiring knowledge but also about building
                  character and developing a sense of responsibility towards
                  society.
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  As we move forward, we remain committed to our vision of
                  excellence and our mission to educate, empower, and inspire. I
                  look forward to another successful year filled with
                  achievements, discoveries, and joyous moments.
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""}`}>
                  Thank you for being a part of the Sandeepani family. Together,
                  let us continue to build a brighter future.
                </div>

                <button class="read-more-button" onClick={toggleReadMore}>
                  {" "}
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpArrow />
      <Footer />
    </div>
  );
};

export default Home;
