import Header from "./Header";
// import "../stylesheets/News.css";
import image7 from "../resources/home/snap1.png";
import Footer from "./Footer";
const News = () => {
  return (
    <div>
      <Header />
      <div className="news-container">
        <div className="news-sec1">
          <div className="news-sec1-title">Opening Soon</div>
          <br />
          <div className="news-sec1-para">
            Exciting news! New "SANDEEPANI PU College" is opening soon at
            Vijayanagar - BANGALORE, HOSADURGA and TARIKERE, offering
            state-of-the-art facilities and a diverse range of courses to
            empower the next generation of leaders. Stay tuned for more updates
            on enrollment and program details!
          </div>
          <div className="news-sec-img">
            <img src={image7} alt="image7" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default News;
