import Header from "./Header";
import Footer from "./Footer";
// import "../stylesheets/Facilities.css";
const Facilities = () => {
  return (
    <div>
      <Header />
      Here goes the facilities page
      <Footer />
    </div>
  );
};

export default Facilities;
