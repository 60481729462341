// import "../stylesheets/Footer.css";

const Footer = () => {
  return (
    <footer className="b1-footer">
      <div className="b1-footer-content">
        <div className="b1-footer-left">
          <div className="b1-ft-title">
            Sandeepani International Residential School
          </div>
          <div className="b1-caption">
            We enable personalised growth for every child
          </div>
          <div>
          impacting thousands of students across schools.
          </div>
          
          <div className="b1-ft-title">About Us</div>
          <div>here goes about</div>

          <div className="b1-ft-title">Quick Links</div>
          <div>here goes links</div>

        </div>
        <div className="b1-footer-right">
          <div className="b1-ft-title">Contact Us</div>
          <div>Phone: +91 95353043390</div>
          <div>Email: sandeepanischooltlk2022@gmail.com</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
