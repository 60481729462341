// import "./UpArrow.css";
const UpArrow = () => {
  function arrow() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <div className="home-arrow" onClick={() => arrow()}>
      &#x2191;
    </div>
  );
};
export default UpArrow;
