import React, { useRef } from "react";
import Header from "./Header";
// import "../stylesheets/Contact.css";
import Footer from "./Footer";
import phone from "../../../resources/contact/phone.svg";
import mail from "../../../resources/contact/mail.svg";

const Contact = () => {
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbwM8u801YfDnyg-S5uucuLpe6O4OuwMrzqborwH6tguJqyW6eECx0jkZ1BmC_P6m6CF/exec";

  const formRef = useRef(null);

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;

    form.querySelector("#date").value = getCurrentDate();

    fetch(scriptURL, { method: "POST", body: new FormData(form) })
      .then(() =>
        alert("Thank you! Your form has been submitted successfully.")
      )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => console.error("Error!", error.message));
  };

  return (
    <div>
      <Header />
      <div className="contact-container">
        <div className="contact1-container">
          <div className="contact-form">
            <div>
              <h2>CONTACT US</h2>
              <p>
                Feel free to contact us any time. We will get back to you soon
              </p>

              <form ref={formRef} onSubmit={handleSubmit} name="sandeepani">
                <input
                  type="text"
                  id="name"
                  placeholder="Name"
                  name="name"
                  required
                />
                <hr />
                <input
                  type="tel"
                  id="phone"
                  placeholder="Phone Number"
                  name="phone"
                  minLength={10}
                  maxLength={10}
                  required
                />
                <hr />
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  name="email"
                  required
                />
                <hr />
                <input
                  type="text"
                  id="address"
                  placeholder="Address"
                  name="address"
                  required
                />
                <hr />
                <textarea
                  id="message"
                  placeholder="Message"
                  name="message"
                  rows="1"
                ></textarea>
                <hr />
                <input type="hidden" className="date" name="date" />
                <div className="submit">
                  <input type="submit" value="Send Now" />
                </div>
              </form>
            </div>
          </div>

          <div className="red-big-box">
            <div className="contact-info">
              <div className="red-box" />
              <h3>Sandeepani Group of Schools</h3>
              <div className="phoneCont">
                <img src={phone} alt="Phone Icon" />
                <p>9535304339</p>
              </div>
              <div className="mailCont">
                <img src={mail} alt="Mail Icon" />
                <p>sandeepanischooltlk2022@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
