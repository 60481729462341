import React, { useState } from 'react';
// import '../stylesheets/Login.css';
import Header from './Header';
import { Link } from 'react-router-dom';

const Login = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [identifierError, setIdentifierError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validateInput = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobilePattern = /^[0-9]{10}$/;
    let isValid = true;

    // Reset previous errors
    setIdentifierError('');
    setPasswordError('');

    if (!emailPattern.test(identifier) && !mobilePattern.test(identifier)) {
      setIdentifierError('Please enter a valid email or mobile number');
      isValid = false;
    }

    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInput()) {
      // login logic 
      console.log('Login:', { identifier, password });
    }
  };

  return (
    <div>
        <Header/>
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <input
            type="text"
            placeholder="Email or Mobile Number"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            className="login-input"
            required
          />
          {identifierError && <div className="error">{identifierError}</div>}
        </div>
        <div className="input-container">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            required
          />
          {passwordError && <div className="error">{passwordError}</div>}
        </div>
        <button type="submit" className="login-button">Login</button>
      </form>
      <Link to="/sandeepanihitechschool/registration">Register?</Link>
    </div>
  
    </div>
  );
};

export default Login;
